/**
 * Groups store.
 *
 */

import { Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { getSecurityGroupsList } from '@/api/users';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { PageSort } from '@/lib/layouts/page/page.interface';

import filterModel from './filter';
import SettingsGroupsFilter from './filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { UITable } from '@/lib/util/tableUtils';

export const MODULE_NAME = 'settingsGroups';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class GroupsModule extends PageBaseModule {
  filter: Filter;
  selectDefault = {
    id: '0',
    value: '',
  };

  constructor(module: GroupsModule) {
    super(module);

    const page = new PageEntity();
    page.setTitle('Группы');
    page.setTitleAdd('Добавление Группы');
    page.values.actionPagination = 'settingsGroups/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(SettingsGroupsFilter);
    this.filter.setFilterName('groupsFilter');
    this.filter.setTemplateClassName('template-sm');
    this.filter.setBtnClassName('row-3');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.employeesSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.filter.init();

    this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = (await this.getSortForRequest()).replace('fullName', 'lastName');
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = await this.getFilterForRequest();
      const result = await getSecurityGroupsList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      await this.setList(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: error.response.data.errors.fields,
        type: 'fail',
      });
    }
  }

  @Action({ rawError: true })
  getFilterForRequest() {
    return this.filter.filterSettings.filter;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);
    const tableInfo = uiTable
      .setProperties('isActive', {
        id: 'isActive',
        sortable: false,
        width: '32px',
        name: 'A',
        align: 'center',
      })

      .addColumnByIndex(1, {
        id: 'users',
        name: 'Пользователи',
        visible: true,
        sortable: false,
      })

      .setSortableValues(sorts)

      .getTable();

    this.context.commit('SET_TABLE', tableInfo as any);
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('setPageAmountStorageValue', {
      moduleName: MODULE_NAME,
      number,
    });
    await this.context.commit('SET_PAGE_AMOUNT_ITEMS', number);
    await this.getList();
  }

  @Action({ rawError: true })
  clearSort() {
    this.context.commit('SET_SORT', {});
  }
}

export default getModule(GroupsModule);
