
import { ref } from 'vue';
import { Component, Vue } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import FormSelect from '@/components/form/Select/index.vue';
import formValidate from '@/lib/formFactory/validate';

import GroupsModule from '@/store/settings/groups';
import SettingsModule from '@/store/settings';
import AppTable from '@/components/ui/table/Table.vue';
import IconFont from '@/components/icons/IconFont.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';

import UsersModule from '@/store/settings/users';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppCol,
    AppRow,
    StatusActive,
    IconFont,
    AppTable,
    TabsNav,
    AppHeaderBtnAdd,
    ArrowReturn,
    AppHeaderMain,
    FormSelect,
  },
})
export default class GroupsMain extends Vue {
  form = ref(null);
  formValidate = formValidate(this.form);

  get tabsNav() {
    return SettingsModule.tabsNav;
  }

  get currentTab() {
    return SettingsModule.allTabs.groups?.id;
  }

  get settings() {
    return GroupsModule.pageSettings;
  }

  get filterSettings() {
    return GroupsModule.filter.filterSettings;
  }

  toAdd() {
    this.$router.push({ name: 'group_add' });
  }

  selectAmount(value: string) {
    GroupsModule.updatePageAmountItems(value);
  }

  async updateList() {
    await GroupsModule.setPageToBegin();
    await GroupsModule.filter.updateFilter();
    await GroupsModule.getList();
  }

  sort(header: { id: string; sort: { value: string } }) {
    GroupsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async resetFilters() {
    await GroupsModule.setPageToBegin();
    await GroupsModule.clearSort();
    await GroupsModule.filter.resetFilter();
    await GroupsModule.getList();
  }

  showUsersList(id: string, value: string) {
    UsersModule.filter.updateSelect({ key: 'securityGroup', value: { id: id, value: value } });
    UsersModule.filter.updateFilter();

    setTimeout(() => {
      this.$router.push({ path: '/settings/users' });
    });
  }

  mounted() {
    SettingsModule.initTabsItems();

    GroupsModule.init();
  }
}
