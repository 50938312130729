import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class GroupsFilter {
  filterHandlers = {
    update: 'settingsGroupsFilter/updateFilter',
    reset: 'settingsGroupsFilter/resetFilter',
    resetState: 'settingsGroupsFilter/resetFilterState',
    removeSelected: 'settingsGroupsFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();
    filter.createSearchEntity('fullName');
    filter.setPlaceholder('fullName', 'По имени');
    filter.setName('fullName', 'search_fullName');
    filter.setPreviewName('fullName', 'Группа');
    filter.setIconClassName('fullName', 'icon-user-search');
    filter.setAction('fullName', 'settingsGroupsFilter/updateSearch');

    this.filterModel = filter.filterModel;
  }
}
